import { graphql } from "gatsby"
import React from "react"
import GallerySerializer, { Gallery } from "../../serializers/Gallery"
import Layout from "../layout/Layout/Layout"
import GoBack from "../navigation/GoBack/GoBack"
import SEO from "../SEO/SEO"

interface GalleryPageProps {
  data: {
    sanityGallery: Gallery
  }
}

const GalleryPage: React.FC<GalleryPageProps> = ({ data }) => {
  return (
    <Layout>
      <div
        style={{
          maxWidth: `1000px`,
          margin: `auto`,
          marginBottom: `25vh`,
        }}
      >
        <SEO
          title={data.sanityGallery.title || `Gallery`}
          description={`A collection of hiking pictures ${
            data.sanityGallery?.title ? `from ${data.sanityGallery?.title}` : ``
          }`}
          image=""
          article={false}
        />
        <h1>{data.sanityGallery.title}</h1>
        <GallerySerializer node={data.sanityGallery} />
      </div>
    </Layout>
  )
}

export default GalleryPage

export const gallery = graphql`
  query Gallery($id: String) {
    sanityGallery(_id: { eq: $id }) {
      ...GalleryDetails
    }
  }
`
